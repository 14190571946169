@import "../../assets/style/mixin";


.about-sec{
  margin-top: 120px;
  background-color: #00204a;
  padding: 80px 0;
  @include screen-max-width-991{
    padding: 60px 0;
    margin-top: 80px;
  }
  .about-cnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    gap: 40px;
    @include screen-max-width-1200{
      gap: 20px;
    }
    @include screen-max-width-991{
      flex-direction: column;
      align-items: flex-start;
    }
    .about-info{
      position: relative;
      max-width: 600px;
      width: 100%;
      @include screen-max-width-1200{
        max-width: 450px;
      }
      @include screen-max-width-991{
        max-width: 100%;
      }
      h2{
        color: #ffffff;
      }
      p{
        margin-top: 40px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: #ffffff;
        @include screen-max-width-1200{
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          margin-top: 25px;
        }
        @include screen-max-width-991{
          margin-top: 15px;
        }
        @include screen-max-width-576{
          font-size: 16px;
        }
      }
    }
  .about-photo{
    max-width: 700px;
    width: 100%;
    display: flex;
      img{
        border-radius: 10px;
      }
    @include screen-max-width-991{
      max-width: 100%;
      margin-top: 15px;
    }
    }
  }
}