@import "../../assets/style/mixin";


.banner-sec{
  position: relative;
  padding-top: 0;
  height: 100vh;
  min-height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
  }
  .banner-title{
    position: relative;
    h1{
      font-size: 50px;
      text-transform: uppercase;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
      @include screen-max-width-1200{
        font-size: 44px;
        line-height: 55px;
      }
      @include screen-max-width-991{
        font-size: 40px;
        line-height: 50px;
      }
      @include screen-max-width-576{
        font-size: 32px;
        line-height: 42px;
      }
    }
    p{
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #ffffff;
      max-width: 1000px;
      width: 100%;
      margin: 40px auto 0;
      @include screen-max-width-1200{
        font-size: 26px;
      }
      @include screen-max-width-991{
        font-size: 22px;
      }
      @include screen-max-width-767{
        font-size: 20px;
        line-height: 30px;
      }
      @include screen-max-width-576{
        font-size: 18px;
        line-height: 26px;
      }
    }
    .see-more{
      width: max-content;
      margin: 50px auto;
      text-align: center;
      font-size: 22px;
      color: #ffffff;
      transition: 0.3s;
      position: relative;
      padding-bottom: 30px;
      cursor: pointer;
      @include screen-max-width-767{
        margin: 40px auto;
        font-size: 20px;
        padding-bottom: 25px;
      }
      &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) rotate(135deg);
        width: 14px;
        height: 14px;
        border-top: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        @include screen-max-width-767{
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}
