@import "../../assets/style/mixin";


.supervision-sec{
  margin-top: 140px;
  @include screen-max-width-991{
    margin-top: 80px;
  }
  .supervision-cnt{
    margin-top: 50px;
    gap: 30px;
    @include screen-max-width-576{
      margin-top: 30px;
    }
    .supervision-box{
      width: calc(50% - 15px);
      @include screen-max-width-991{
        width: 100%;
      }
      h4{
        color: #000000;
        font-size: 22px;
        @include screen-max-width-767{
          font-size: 20px;
        }
      }
      span{
        display: block;
        width: 80px;
        height: 1px;
        background: #00204a;
        margin-top: 15px;
      }
      p{
        color: #000000;
        font-size: 18px;
        line-height: 24px;
        margin-top: 15px;
        @include screen-max-width-767{
          font-size: 16px;
        }
      }
    }
  }
}