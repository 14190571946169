@import "../../assets/style/mixin";

.services-sec{
  margin-top: 140px;
  @include screen-max-width-991{
    margin-top: 100px;
  }
  .services-cnt{
    margin-top: 40px;
    h4{
      font-size: 28px;
      color: #000000;
      line-height: 36px;
      @include screen-max-width-767{
        font-size: 22px;
        line-height: 30px;
      }
    }
    p{
      margin-top: 20px;
      font-size: 20px;
      line-height: 26px;
      color: #000000;
      @include screen-max-width-991{
        font-size: 18px;
      }
      @include screen-max-width-767{
        font-size: 16px;
      }
    }
    .services-photo{
      gap: 30px;
      margin-top: 30px;
      @include screen-max-width-1200{
        gap: 20px;
      }
      @include screen-max-width-767{
        flex-direction: column;
      }
      img{
        width: 49%;
        border-radius: 10px;
        @include screen-max-width-767{
          width: 100%;
        }
      }
    }

  }
}