@import "../../assets/style/mixin";

.project-sec {
  margin-top: 120px;
  @include screen-max-width-991 {
    margin-top: 80px;
  }
}

.project-wrap {
  margin-top: 40px;
  @include screen-max-width-991 {
    margin-top: 20px;
  }
  gap: 20px;

  .project-box {
    width: calc(33.333333% - 14px);
    border-radius: 10px;
    padding: 15px 15px 25px;
    box-shadow: 0 0 11px #00204a3b;
    transition: 0.3s;
    @include screen-max-width-1200 {
      width: calc(50% - 14px);
    }
    @include screen-max-width-767 {
      width: 100%;
    }

    .project-img-slider {
      position: relative;

      .swiper-arrows-item {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        width: 40px;
        height: 40px;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;


        &.swiper-button-prev {
          left: 20px;
        }

        &.swiper-button-next {
          right: 20px;
        }
      }
    }

    &:hover {
      .swiper-arrows-item {
        opacity: 1;
        visibility: visible;
      }
    }

    .project-info {
      margin-top: 20px;

      h3 {
        color: #000000;
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
        @include screen-max-width-991 {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
}
